import React, { useMemo, useState,useEffect } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect,useExpanded} from "react-table";
import { useLocation, useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import GlobleFilter from "../../globalFilter/GlobleFilter";
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight,FaLongArrowAltLeft,FaAngleDown } from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import axios from "axios"
import { NODE_API_URL } from "../../../../env_config";
import SortingTheme1 from "../grid/SortingTheme1";
import { searchResultColumn } from "./Columns";
import ListSkeleton from "../../ListSkeleton";
import ExpandedDetails from "../../expandedDetails/ExpandedDetails";
export const SearchResultTheme1 = (props) => {
    const history = useHistory();
    const [sorting,setSorting] = useState("")
    const location = useLocation();
    const [data,setData] = useState([])
    const [stoneParams,setStoneParams] = useState()
    const [loader,setLoader] = useState(true)
    const [count, setCount] = React.useState(0);
    useEffect(() => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(location.state.diamondparams){
            if(location.state.diamondparams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/diamondcount`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                    "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                     },
                    data: {
                        ...location.state.diamondparams,
                        diamond_type:location.state.diamondparams.fancy_color_diamond && location.state.diamondparams.fancy_color_diamond === "YES"?"NF":"N"
                    },
                }).then(res => {
                    // setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setCount(res.data.diamondcount)
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(location.state.diamondparams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/diamondcount`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                    "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                     },
                     data: {
                        ...location.state.diamondparams,
                        diamond_type:location.state.diamondparams.fancy_color_diamond && location.state.diamondparams.fancy_color_diamond === "YES"?"LF":"L"
                    },
                }).then(res => {
                    // setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setCount(res.data.diamondcount)
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        setLoader(false)
            setData(location.state.diamonddata)
            setStoneParams(location.state.diamondparams)
            if(location.state.diamondparams && location.state.diamondparams.sort_field && location.state.diamondparams.sort_order){
                setSorting(`${location.state.diamondparams.sort_field}_${location.state.diamondparams.sort_order}`)
            }
    },[])
    const columns = useMemo(() => searchResultColumn(props.themeData,props.language), []);
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
             {/* <code>{JSON.stringify({ values: row.row.original }, null, 2)}</code> */}
                <ExpandedDetails row={row.row.original} themeData={props.themeData} ipData={props.ipData} language={props.language}/>
            </>
        ),[data]
      )
    const natGrid = () => {
        history.push({
            pathname: "grid",
            state: {
                diamonddata:[...data.slice(0,100)],
                diamondparams:location.state.diamondparams
            },
            // state: decompress(res.data.ProductDetails),
        });
    };

    const backBtn = () => {
        history.push({ pathname: "/searchdiamond" });
    };
    const nextListPage = () => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(stoneParams){
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/naturaldiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                    //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                     },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page + 1
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page + 1
                        })
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/labgrowndiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                    //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                     },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page + 1
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page + 1
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
    }
    const previousListPage = () => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(stoneParams && stoneParams.page - 1){
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/naturaldiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                    //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                     },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page - 1
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page - 1
                        })
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/labgrowndiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                    //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                     },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page - 1
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page - 1
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
    }
    useEffect(() => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(sorting){
            window.scrollTo(0, 0)
            if(stoneParams){
                let SortingStoneParams = {...stoneParams}
                SortingStoneParams["page"] = 1
                if(sorting === "carat_desc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "carat_asc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "price_desc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "price_asc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "clarity_desc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "clarity_asc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "color_desc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "color_asc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(stoneParams.diamond_type === "N"){
                    setLoader(true)
                    axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/naturaldiamonds`,
                        headers: { 
                            "Content-Type": "application/json",
                            "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                        //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                         },
                        data: SortingStoneParams,
                    }).then(res => {
                        setLoader(false)
                        if(res && res.data && res.data.success){
                            // history.push({
                            //     pathname: "/search-result",
                            //     state: {
                            //         diamonddata:res.data.data,
                            //         diamondparams:SortingStoneParams
                            //     },
                            //     // state: decompress(res.data.ProductDetails),
                            // });
                            setStoneParams(SortingStoneParams)
                            setData([
                                ...res.data.data
                            ])
                        }
                        else{
        
                        }
                    }).catch(err => {
                        
                    })
                }
                if(stoneParams.diamond_type === "L"){
                    setLoader(true)
                    axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/labgrowndiamonds`,
                        headers: { 
                            "Content-Type": "application/json",
                            "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                        //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                         },
                        data: SortingStoneParams,
                    }).then(res => {
                        setLoader(false)
                        if(res && res.data && res.data.success){
                            // history.push({
                            //     pathname: "/search-result",
                            //     state: {
                            //         diamonddata:res.data.data,
                            //         diamondparams:SortingStoneParams
                            //     },
                            //     // state: decompress(res.data.ProductDetails),
                            // });
                            setStoneParams(SortingStoneParams)
                            setData([
                                ...res.data.data
                            ])
                        }
                        else{
                            
                        }
                    }).catch(err => {
                        
                    })
                }
            }
        }
    },[sorting])
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        visibleColumns,
        page,
        // nextPage,
        // previousPage,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        // setGlobalFilter,
        // allColumns,
        // getToggleHideAllColumnsProps,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 100 }
        },
        useGlobalFilter,
        // useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    ...columns,
                    {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click event propagation
                                    row.toggleRowExpanded();
                                }}
                            >
                                {row.isExpanded ? 
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip">Collapse</Tooltip>}
                                    >
                                        <FaAngleDown className="text-success font-size-h6"  title="Collapse"/> 
                                    </OverlayTrigger>
                                : 
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip">Expand</Tooltip>}
                                    >
                                        <FaAngleLeft className="text-primary font-size-h6" title="Expand"/>
                                    </OverlayTrigger>
                                }
                            </span>
                        ),
                        },
                ]
            })
        }
    );
    // if(user === undefined ){
    //     return
    // }
    
    return (
        <>
        
            <div className={`container theme1  ${
                   data.length >0 && data[0].diamond_type === "N" ? "natTab" : "labTab"
                }`}> 
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header bg-white d-flex justify-content-between p-2  align-items-center sticky-top" style={{top:"0px"}}>
                        <div className="card-title m-0 d-flex align-items-center">
                                {/* <h3 className="font-weight-bolder">{props.language.CCMODE_THEME1_GRID_STOCKLIST}</h3> */}
                                <button
                                    className="btn btn-warning btn-sm btn-square mr-3 text-nowrap"
                                    onClick={backBtn}
                                >
                                    <FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}
                                </button>
                            <div className="custom-result-box">
                            {props.language.TOTAL_STONE_COUNT}: {count}
                            </div>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                        {/* <SortingTheme1 setSorting={setSorting} sorting={sorting}/> */}
                            <button
                                className="btn btn-primary btn-sm btn-square mr-3 text-nowrap"
                                onClick={natGrid}
                            >
                                <BsFillGridFill /> {props.language.CCMODE_THEME1_LIST_GRIDBTN}
                            </button>
                            <SortingTheme1 setLoader={setLoader} setSorting={setSorting} sorting={sorting} language={props.language}/>
                        </div>
                    </div>
                    <div className="card-body pt-7 pb-0 mt-n3">
                        {loader?<ListSkeleton/>:
                    <div className="table-responsive w-100">
                    <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle text-nowrap" id={column.id}>
                                                {column.render("Header")}
                                                {/* <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span> */}
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        {page.length?<tbody {...getTableBodyProps()}>
                            {page.map((row,i) => {
                                prepareRow(row)
                                return(
                                    <React.Fragment key={i}>
                                        <tr
                                            {...row.getRowProps()}
                                            key={row.id}
                                            onClick={() => row.toggleRowExpanded()}
                                            style={{ cursor: 'pointer' }} // Change cursor to indicate clickable row
                                        >
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr className='expanded_tr'>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })} 
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>:<tbody><tr><td className="text-center align-middle" colSpan={"100%"}>No Diamonds Found</td></tr></tbody>}
                    </table>
                </div>}
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">
                                {props.language.CCMODE_THEME3_SEARCHDIAMOND_PAGE} {stoneParams?stoneParams.page:1}
                            </span>
                            <div>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <FaAngleDoubleLeft />
                                </button>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => previousListPage()}
                                    // disabled={!canPreviousPage}
                                >
                                    <FaAngleLeft />
                                </button>
                                <span className="font-weight-bold text-muted">
                                    {"  "}
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={stoneParams?stoneParams.page:1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(pageNumber);
                                        }}
                                        disabled={true}
                                        style={{ maxWidth: "70px" }}
                                    />
                                    {"  "}
                                    {"  "}
                                </span>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => nextListPage()}
                                    // disabled={!canNextPage}
                                >
                                    <FaAngleRight />
                                </button>
                                <button
                                    className="btn btn-sm btn-light"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <FaAngleDoubleRight />
                                </button>
                            </div>
                            <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">
                                    {props.language.CCMODE_THEME3_SEARCHDIAMOND_ROWSPERPAGE}:{" "}
                                </span>
                                <select
                                    className="form-select ml-2 custom-select py-1"
                                    value={pageSize}
                                    onChange={(e) =>
                                        setPageSize(Number(e.target.value))
                                    }
                                >
                                    {[100].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
