import React, {  useState , useEffect} from "react";
import { GridCardContent } from "./card/gridCardContent";
import {   useHistory,useLocation } from 'react-router-dom';
import { FaList,FaLongArrowAltLeft } from "react-icons/fa";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
import SortingTheme1 from "./SortingTheme1";
import GridSkeleton from "../../GridSkeleton";
export const GridCardTheme1 = (props) => {
    const [count, setCount] = React.useState(0);
    const history = useHistory();
    const location = useLocation();
    const [loader,setLoader] = useState(true)
    // const natData = diamondsearch.value
    const [natData,setNatData] = useState([])
    const [stoneParams,setStoneParams] = useState()
    const [sorting,setSorting] = useState("")
    window.searchData = natData;
    const [infiniteloader,setinfiniteLoader] = useState(false)
    React.useEffect(() => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(location.state.diamondparams){
            if(location.state.diamondparams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/diamondcount`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                     },
                    data: {
                        ...location.state.diamondparams,
                        diamond_type:location.state.diamondparams.fancy_color_diamond && location.state.diamondparams.fancy_color_diamond === "YES"?"NF":"N"
                    },
                }).then(res => {
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setCount(res.data.diamondcount)
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(location.state.diamondparams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/diamondcount`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                        "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`                     },
                     data: {
                        ...location.state.diamondparams,
                        diamond_type:location.state.diamondparams.fancy_color_diamond && location.state.diamondparams.fancy_color_diamond === "YES"?"LF":"L"
                    },
                }).then(res => {
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setCount(res.data.diamondcount)
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        setLoader(false)
        setNatData(location.state.diamonddata)
        setStoneParams(location.state.diamondparams)
        if(location.state.diamondparams && location.state.diamondparams.sort_field && location.state.diamondparams.sort_order){
            setSorting(`${location.state.diamondparams.sort_field}_${location.state.diamondparams.sort_order}`)
        }
    }, []);

    useEffect(() => {
        fetchData(stoneParams);
    }, [stoneParams]);

    const fetchData = (stoneParams) => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        setinfiniteLoader(true)
        if(stoneParams && stoneParams.page > 1){
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/naturaldiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                        "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                     },
                    data: stoneParams,
                }).then(res => {
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setNatData([
                            ...natData,
                            ...res.data.data
                        ])
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/labgrowndiamonds`,
                    headers: { 
                        "Content-Type": "application/json",
                        "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                     },
                    data: stoneParams,
                }).then(res => {
                    setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        setNatData([
                            ...natData,
                            ...res.data.data
                        ])
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        // setTimeout(function() { 
        //     setNatData([
        //         ...natData
        //     ]);
        // setinfiniteLoader(false)

        // }, 2000);
    };

    const onScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
        ) {
            setStoneParams({
                ...stoneParams,
                page:stoneParams.page + 1
            })
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [natData]);
    const natList = () =>{
        history.push({
            pathname: "search-result",
            state: {
                diamonddata:[...natData.slice(0,100)],
                diamondparams:location.state.diamondparams
            },
            // state: decompress(res.data.ProductDetails),
        });
    }
    const backBtn = () =>{
        history.push({  pathname: '/searchdiamond'})
    }
    useEffect(() => {
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        if(sorting){
            window.scrollTo(0, 0)
            if(stoneParams){
                let SortingStoneParams = {...stoneParams}
                SortingStoneParams["page"] = 1
                if(sorting === "carat_desc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "carat_asc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "price_desc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "price_asc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "clarity_desc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "clarity_asc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "color_desc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "color_asc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(stoneParams.diamond_type === "N"){
                    setLoader(true)
                    axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/naturaldiamonds`,
                        headers: { 
                            "Content-Type": "application/json",
                            "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                         },
                        data: SortingStoneParams,
                    }).then(res => {
                        setLoader(false)
                        setinfiniteLoader(false)
                        if(res && res.data && res.data.success){
                            // history.push({
                            //     pathname: "/grid",
                            //     state: {
                            //         diamonddata:res.data.data,
                            //         diamondparams:SortingStoneParams
                            //     },
                            //     // state: decompress(res.data.ProductDetails),
                            // });
                            setStoneParams(SortingStoneParams)
                            setNatData([
                                ...res.data.data
                            ])
                        }
                        else{
        
                        }
                    }).catch(err => {
                        
                    })
                }
                if(stoneParams.diamond_type === "L"){
                    setLoader(true)
                    axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_ENV === "production"?"https://api.global.diamondsoncall.com":"https://api.staging.global.diamondsupload.com"}/feed/labgrowndiamonds`,
                        headers: { 
                            "Content-Type": "application/json",
                            "Parent-Domain":parentDomain,
                       "Authorization": `Bearer ${localStorage.getItem("accesstoken")}`
                         },
                        data: SortingStoneParams,
                    }).then(res => {
                        setLoader(false)
                        setinfiniteLoader(false)
                        if(res && res.data && res.data.success){
                            // history.push({
                            //     pathname: "/grid",
                            //     state: {
                            //         diamonddata:res.data.data,
                            //         diamondparams:SortingStoneParams
                            //     },
                            //     // state: decompress(res.data.ProductDetails),
                            // });
                            setStoneParams(SortingStoneParams)
                            setNatData([
                                ...res.data.data
                            ])
                        }
                        else{
                            
                        }
                    }).catch(err => {
                        
                    })
                }
            }
        }
    },[sorting])
    return (
        <>
            {/* <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={fetchData}
                hasMore={noMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            > */}
                <div className={`container ${
                   natData.length >0 && natData[0].diamond_type === "N" ? "natTab" : "labTab"
                } theme1`}>
                   <div className="card">
                        <div className="card-header d-flex justify-content-between p-2  align-items-center sticky-top" style={{top:"0px"}}>
                            <div className="card-title m-0 d-flex align-items-center">
                                {/* <h3 className="font-weight-bolder">{props.language.CCMODE_THEME1_GRID_STOCKLIST}</h3> */}
                                <button className="btn btn-warning btn-sm btn-square" onClick={backBtn}><FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}</button>
                                <div className="custom-result-box">
                                    {props.language.TOTAL_STONE_COUNT}: {count}
                                </div>
                            </div>
                            <div className="card-toolbar d-flex">
                                {/* <button className="btn btn-primary btn-sm btn-square mr-3" onClick={selectSession}><FaList/> selectSession</button> */}
                                {/* <SortingTheme1 setSorting={setSorting} sorting={sorting}/> */}
                                <button className="btn btn-primary btn-sm btn-square mr-2" onClick={natList}><FaList/> {props.language.CCMODE_THEME1_GRID_LISTBTN}</button>
                                <SortingTheme1 setSorting={setSorting} sorting={sorting} language={props.language}/>
                                {/* <button className="btn btn-warning btn-sm btn-square" onClick={backBtn}><FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}</button> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row m-0 mt-5">
                                {/* {JSON.stringify(natData)} */}
                                {loader?<GridSkeleton/>:
                                    natData.map((val) => {
                                        return (
                                            <GridCardContent
                                                key={val.ProductId}
                                                {...val}
                                                setNatData={setNatData}
                                                natData={natData}
                                                stoneParams={stoneParams}
                                                language={props.language}
                                                themeData={props.themeData}
                                                ipData={props.ipData}
                                            />
                                        );
                                    })
                                }
                                {infiniteloader?<GridSkeleton/>:""}
                            </div>
                            {natData.length % 100?<div className="text-center mt-5">No More Records</div>:""}
                        </div>
                   </div>
                </div>
            {/* </InfiniteScroll> */}
        </>
    );
};
