import React, { useState } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { useHistory } from "react-router-dom";
import {Modal,Button} from "react-bootstrap";
import { MainImagePopup } from "../popup/MainImagePopup";
import VideoIframe from "../popup/VideoIframe";
// import { DETAILPAGE_LOG } from "../../../env_config";
// import axios from "axios";
import { SendInquiry } from "../popup/sendInquiry";

export default function ExpandedDetails({row,themeData,ipData,language}) {
    const history = useHistory();
    const [mainimageclick,setMainImageClick] = useState(false)
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }
    function openVideoModal() {
        setShowVideo(true)
    }
    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }
    function handelDetails(){
        history.push({  pathname: '/details', state:{
            StockID:row.STOCK_ID,
            diamond_type:row.COLOR?row.diamond_type:row.diamond_type + "F",
            ipData:ipData
        }})
        window.scrollTo(0, 0)
    }
    function Currency(){
        if (themeData && themeData.api_currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }  else if (themeData && themeData.api_currency === "SGD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "THB"){
            const  currencyIcon = "฿"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "MYR"){
            const  currencyIcon = "RM"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "BND"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (themeData && themeData.api_currency === "DKK"){
            const  currencyIcon = "kr."
            return currencyIcon
        } else if (themeData && themeData.api_currency === "SEK"){
            const  currencyIcon = "kr"
            return currencyIcon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
            <div className="bg-gray-300 confirmExpand p-5 border">
                <div className="bg-white p-5 border">
                    <div className="row m-0 align-items-center">
                        <div className="col-4 d-flex">
                            <ul className="img_thumb_box mt-4 ml-5 pl-0">
                                <li className="img_thumb">
                                    {row.IMAGE ? (
                                        <div>
                                        <MainImagePopup Image={row.IMAGE} Shape={row.SHAPE} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                        <img
                                            className="img thumb"
                                            alt={row.SHAPE}
                                            src={row.IMAGE}
                                            onClick={() => setMainImageClick(true)}
                                        />
                                        </div>
                                    ) : (
                                        <div>
                                            <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.SHAPE.toUpperCase()}-no.png` )} Shape={row.SHAPE} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                                        <img
                                            className="img thumb"
                                            alt="shape"
                                            src={toAbsoluteUrl( `/media/shape/${row.SHAPE.toUpperCase()}-no.png` )}
                                        />
                                        </div>
                                    )}
                                </li>
                                {row.VIDEO  ? (
                                    <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                        <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                    </li>
                                ):('')}
                                {row.AWS_Heart ? (
                                    <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                        <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                    </li>
                                    
                                ):('')}
                                {row.AWS_Arrow ? (
                                    <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                        <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                    </li>
                                ):('')}
                                {row.AWS_Asset ? (
                                    <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                        <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                    </li>
                                ):('')}
                            </ul>
                            <div className="img_box mt-4">
                                {row.IMAGE ? (
                                    <div>
                                        <MainImagePopup Image={row.IMAGE} Shape={row.SHAPE} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row.SHAPE}
                                        src={row.IMAGE}
                                        onClick={() => setMainImageClick(true)}

                                    />
                                    </div>
                                ) : (
                                    <div>
                                        <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.SHAPE.toUpperCase()}-no.png` )} Shape={row.SHAPE} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                    <img
                                        className="img-fluid"
                                        alt="shape"
                                        src={toAbsoluteUrl(
                                            `/media/shape/${row.SHAPE.toUpperCase()}-no.png`
                                        )}
                                    />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row m-0">
                                                <div className="col-5 p-0 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_STOCKID} </div>
                                                <div className="col-7"><span>{row.STOCK_ID}</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 p-0 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_SHAPE}</div>
                                                <div className="col-7"><span>{row.SHAPE}</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 p-0 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_LAB} </div>
                                                <div className="col-7"><span>{row.LAB}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-6 px-1">
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_CARAT}</div>
                                                <div className="col-7"><span>{row.CARAT}</span></div>
                                            </div>
                                            
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_COLOR}</div>
                                                <div className="col-7"><span>{!row.FANCY_COLOR ? row.COLOR :<>{row.FANCY_INTENSITY} {row.FANCY_OVERTONE} {row.FANCY_COLOR}</> }</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_CLARITY}</div>
                                                <div className="col-7"><span>{row.CLARITY}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="w-100 m-0 mb-2" />
                                    <div className="row">
                                        <div className="col-6 px-1">
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_CUT}</div>
                                                <div className="col-7"><span>{row.CUT}</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_POLISH}</div>
                                                <div className="col-7"><span>{row.POLISH}</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_SYMMETRY}</div>
                                                <div className="col-7"><span>{row.SYMMETRY}</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_FLOUR}</div>
                                                <div className="col-7"><span>{row.FLUORESCENCE}</span></div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-6 px-1">
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_MEASUREMENT}</div>
                                                <div className="col-7"><span className="d-flex">{parseFloat(row.LENGTH).toFixed(2)} x {parseFloat(row.WIDTH).toFixed(2)} x {parseFloat(row.DEPTH).toFixed(2)} </span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_TABLE}</div>
                                                <div className="col-7"><span>{parseFloat(row.TABLE_PER).toFixed(2)}%</span></div>
                                            </div>
                                            <div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_DIAMOND_DETAILS_DEPTH}</div>
                                                <div className="col-7"><span>{parseFloat(row.DEPTH_PER).toFixed(2)}%</span></div>
                                            </div>
                                            {themeData && !themeData.ifm_no_price?<div className="row m-0">
                                                <div className="col-5 font-weight-bold">{language.CCMODE_SHORTLISTPAGE_PRICE}
                                                {/* if ($no_price == 0) {
                                                    $detail .= 'Price';
                                                }
                                                $detail .= ' */}
                                                </div>
                                                <div className="col-7">
                                                    {Currency()}{numberWithCommas(row.TOTAL_PRICE)}
                                                {/* if ($no_price == 0) {
                                                    $detail .= '<span>$' . number_format($main_price_total, 2) . '</h4>';
                                                }
                                                $detail .= ' */}
                                                </div>
                                            </div>:""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 align-self-end">
                            <SendInquiry diamond_details={row} ipData={ipData} language={language}/>
                            <button className="btn btn-secondary btn-sm my-1" onClick={()=>handelDetails()}>{language.CCMODE_THEME1_GRID_DETAILBTN}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.AWS_Heart} alt={row.SHAPE} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.AWS_Arrow} alt={row.SHAPE} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.AWS_Asset} alt={row.SHAPE} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${row.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${row.CERTIFICATE_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
        </>
    );
}
